import type { UseFetchOptions } from '@/hooks/useRequest'
import useRequest from '@/hooks/useRequest'
import type { BaseResult } from '@ahooksjs/use-request/lib/types'
import type {
  RegularHoursRequest,
  RegularHoursResponse,
  SpecialHoursRequest,
  SpecialHoursResponse,
  StoreDetailResponse,
  StoreInfoRequest,
  StoreInfoResponse,
  StoreListRequest,
  StoreListResponse,
} from './data.d'

export * from './data.d'

/**
 * 查询单个店铺信息
 */
export function useFetchStoreInfo(params: StoreInfoRequest) {
  return useRequest<BaseResult<StoreInfoResponse, any>>(
    () => ({
      url: '/api/seller/merchants',
      method: 'GET',
      params,
    }),
    {
      manual: true,
    },
  )
}

/**
 * 查询店铺信息
 */
export function useFetchStoreList(params: StoreListRequest) {
  return useRequest<BaseResult<StoreListResponse, any>>(
    () => ({
      url: '/api/seller/stores',
      method: 'POST',
      data: params,
    }),
    {
      manual: true,
      defaultLoading: true,
    },
  )
}

/**
 * 查询店铺信息
 */
export function useFetchStoreDetail() {
  return useRequest<BaseResult<StoreDetailResponse, any>>(
    {
      url: '/api/seller/store',
      method: 'GET',
    },
    {
      manual: true,
      defaultLoading: true,
    },
  )
}

/**
 * 查询店铺营业时间
 */
export function useFetchRegularHours() {
  return useRequest<BaseResult<RegularHoursResponse, any>>(
    {
      url: '/api/seller/store/regular-hours',
      method: 'GET',
    },
    {
      manual: true,
      defaultLoading: true,
    },
  )
}

/**
 * 设置店铺营业时间
 * @param RegularHoursRequest
 */
export function useSaveRegularHours(options?: UseFetchOptions<any, any>) {
  return useRequest(
    (params: RegularHoursRequest) => ({
      url: '/api/seller/store/regular-hours',
      method: 'POST',
      data: params,
    }),
    {
      manual: true,
      ...options,
    },
  )
}

/**
 * 查询店铺特殊营业时间
 */
export function useFetchSpecialHours() {
  return useRequest<BaseResult<SpecialHoursResponse, any>>(
    {
      url: '/api/seller/store/special-hours',
      method: 'GET',
    },
    {
      manual: true,
      defaultLoading: true,
    },
  )
}

/**
 * 设置店铺特殊营业时间
 * @param SpecialHoursResponse
 */
export function useSaveSpecialHours(options?: UseFetchOptions<any, any>) {
  return useRequest(
    (params: SpecialHoursRequest) => ({
      url: '/api/seller/store/special-hours',
      method: 'POST',
      data: params,
    }),
    {
      manual: true,
      ...options,
    },
  )
}
