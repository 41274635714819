import actions from '../../../constants/actionTypes'
import { trans } from '../../../localization'

const initialState = {
  error: {
    isShow: false,
    message: '',
    extraData: {},
  },
  confirm: {
    isOpen: false,
    title: 'Confirm',
    message: 'Are you sure ?',
    onOk: () => {},
    onCancel: () => {},
  },
  chooseRestaurant: {
    refreshList: () => {},
    isShowAllRestaurantOption: true,
  },
}

function universalReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_GENERAL_ERROR: {
      const { errorMessage, errorExtraData } = action.response
      return {
        ...state,
        error: {
          ...state.error,
          isShow: true,
          message: trans(errorMessage),
          extraData: errorExtraData,
        },
      }
    }
    case actions.CLEAR_GENERAL_ERROR: {
      return {
        ...state,
        error: {
          ...state.error,
          isShow: false,
        },
      }
    }
    case actions.CHANGE_GENERAL_CONFIRM_DIALOG: {
      const { response } = action
      return {
        ...state,
        confirm: {
          ...state.confirm,
          ...response,
        },
      }
    }
    case actions.GET_CURRENT_RESTAURANT: {
      const { refreshList, isShowAllRestaurantOption = true } = action.response
      return {
        ...state,
        chooseRestaurant: {
          ...state.chooseRestaurant,
          refreshList,
          isShowAllRestaurantOption,
        },
      }
    }
    default:
      return state
  }
}

export default universalReducer
