import React from 'react'
import Modal, { ModalAction, ModalBody, ModalButton, ModalHeader } from './Modal'
import { trans } from '../../localization'

class GeneralModal extends React.Component {
  handleClose = () => {
    const { onClose, extraData } = this.props
    const { onBeforeClose = () => Promise.resolve(), onAfterClose = () => Promise.resolve() } =
      extraData
    onBeforeClose()
      .then(() => onClose())
      .then(onAfterClose)
  }

  render() {
    const { open, message, extraData } = this.props
    // EX: action = { name: Close, onClick: () => {} }
    const {
      title = trans('error'),
      extraActions = [],
      width = '50%',
      withCloseButton = true,
    } = extraData
    return (
      <Modal
        isOpen={open}
        onRequestClose={this.handleClose}
        zIndex={3}
        withCloseButton={!title}
        width={width}
      >
        {title && <ModalHeader>{title}</ModalHeader>}
        <ModalBody>
          <div style={{ margin: 10 }}>{message}</div>
        </ModalBody>
        <ModalAction>
          {extraActions.map((action) => {
            const { name, type, onClick, closeAfterAction = true } = action
            return (
              <ModalButton
                key={name}
                onClick={() => {
                  onClick()
                  if (closeAfterAction) this.handleClose()
                }}
                type={type}
              >
                {name}
              </ModalButton>
            )
          })}
          {withCloseButton && (
            <ModalButton type="cancel" onClick={this.handleClose}>
              {trans('common_close')}
            </ModalButton>
          )}
        </ModalAction>
      </Modal>
    )
  }
}

export default GeneralModal
