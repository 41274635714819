import { ItemStatus } from '@/constants/order.constant'
import { Collapse } from 'antd'
import { memo, useMemo, useState } from 'react'
import { DownOutlined } from '@ant-design/icons'
import { getFormattedPrice } from '../../utils'
import { t } from '@/utils/i18n'
import { OrderItemsProps } from '../../data'
import './index.less'
import Price from '../Price'

const OrderItems = ({ items }: OrderItemsProps) => {
  const [activeKeys, setActiveKeys] = useState<string | string[]>([])

  const _items = useMemo(() => {
    return items.map((item) => {
      return {
        ...item,
        optionGroupsExpand: activeKeys.includes(item.id.toString()),
      }
    })
  }, [items, activeKeys])

  return (
    <div className="order-info-container">
      <div className="order-info-header">{t('transaction_detail.label.ordered_items')}</div>
      <ul className="order-info-list">
        {_items.map((item) => {
          const {
            cartItem: { detail, quantity },
            status,
          } = item
          if (!detail || !detail.dish) return null
          if (status === ItemStatus.Deleted) return null
          const formattedUnit = getFormattedPrice(item)
          const header = <span className="item-name">{detail.dish.name}</span>

          return (
            <li className="order-info-item" key={item.id}>
              <div className="order-info">
                <span className="item-num">{quantity}x</span>
                {detail?.optionGroups?.length ? (
                  <Collapse
                    ghost
                    activeKey={activeKeys}
                    expandIconPosition="right"
                    expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? -180 : 0} />}
                    onChange={(key) => {
                      setActiveKeys(key)
                    }}
                  >
                    <Collapse.Panel header={header} key={item.id}>
                      <></>
                    </Collapse.Panel>
                  </Collapse>
                ) : (
                  header
                )}
                <div className="order-info-val">
                  {'listPriceSubTotal' in formattedUnit && (
                    <Price lineThrough price={formattedUnit.listPriceSubTotal!} />
                  )}
                  <Price price={formattedUnit.priceSubTotal} style={{ marginLeft: '10px' }} />
                </div>
              </div>
              {item.optionGroupsExpand && detail?.optionGroups?.length ? (
                <ul className="sub-order-info">
                  {detail.optionGroups.map((optionGroup) => {
                    return (
                      <li key={optionGroup.id}>
                        <span>
                          {optionGroup.name}:{' '}
                          {optionGroup?.options?.map((option) => option.name)?.join(', ')}
                        </span>
                      </li>
                    )
                  })}
                </ul>
              ) : null}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default memo(OrderItems)
