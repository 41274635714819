/* eslint-disable */
import React from 'react'
import LocalizedStrings from 'react-localization'

import store from '../redux/store'

const strings = new LocalizedStrings({
  en: require('./i18n/en.json'),
  vi: require('./i18n/vi.json'),
})

export const trans = (key, params = null) => {
  let lang = store ? store.getState().setting.language.toLowerCase() : 'vi'

  if (strings.getAvailableLanguages().indexOf(lang) > -1) {
    strings.setLanguage(lang)
  } else {
    console.warn(
      'lang not available: ' +
        lang +
        ' ,available languages: ' +
        JSON.stringify(strings.getAvailableLanguages()),
    )
  }

  let string = ''

  if (strings.hasOwnProperty(key)) {
    string = strings[key]
    if (params && Object.prototype.toString.call(params) === '[object Array]') {
      params = params.slice()
      if (Object.prototype.toString.call(params[0]) === '[object Object]') {
        const sections = string.split(/{[0-9]+}/)
        return (
          <span key={key}>
            {sections.map((text, index) => {
              if (index < params.length) {
                return (
                  <span key={`${key}-${index}`}>
                    {text} {params[index]}
                  </span>
                )
              } else {
                return text
              }
            })}
          </span>
        )
      } else {
        let expectParamLength = 0
        let regex = /\{[0-9]\}/g
        let match
        while ((match = regex.exec(string)) !== null) {
          expectParamLength++
        }
        while (params.length < expectParamLength) {
          params.push('')
        }
        params.unshift(string)
        string = strings.formatString.apply(strings, params)
      }
    }
    if (string.indexOf('\n') > -1) {
      let lines = string.split('\n')
      return (
        <span key={key}>
          {lines.map((text, index) => {
            if (index + 1 === lines.length) {
              return text
            } else {
              return (
                <span key={index}>
                  {text}
                  <br />
                </span>
              )
            }
          })}
        </span>
      )
    }
  } else {
    return key
  }
  return string
}
