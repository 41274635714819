import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Footer from './components/Footer/Footer'

import Routes from '../../../routes'
import { initializeApp } from './actions'
import UniversalModals from './components/UniversalModal/UniversalModals'
import Loading from '../components/Loading/Loading'

import '../assets/common/scss/core.scss'

class NowApp extends React.Component {
  componentDidMount() {
    const { initializeAppConnect } = this.props
    initializeAppConnect()
  }

  render() {
    const { isLoading } = this.props
    return (
      <div>
        <div className="wrapper">
          <section className="main-content">
            <Routes />
          </section>
          <Footer />
        </div>
        <UniversalModals />
        <Loading loading={isLoading} />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.app.isLoading,
})

const mapDispatchToProps = (dispatch) => ({
  initializeAppConnect: () => {
    dispatch(initializeApp())
  },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NowApp))
