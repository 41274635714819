const Environment = {
  Yapi: 'yapi',
  Test: 'test',
  Uat: 'uat',
  Staging: 'staging',
  Live: 'live',
}

const MerchantApiMappingVn = {
  [Environment.Yapi]: 'https://food-dev.test.now.vn',
  [Environment.Test]: 'https://gmerchant.test.now.vn',
  [Environment.Uat]: 'https://gqamerchant.test.now.vn',
  [Environment.Staging]: 'https://gmerchant.stage.now.vn',
  [Environment.Live]: 'https://gmerchant.deliverynow.vn',
}

const MqttApiMappingVn = {
  [Environment.Yapi]: 'https://mqtt.test.now.vn',
  [Environment.Test]: 'https://mqtt.test.now.vn',
  [Environment.Uat]: 'https://qamqtt.test.now.vn',
  [Environment.Staging]: 'https://mqtt.deliverynow.vn',
  [Environment.Live]: 'https://mqtt.deliverynow.vn',
}

export const getConfig = () => {
  const environment = process.env.APP_ENV || Environment.Test
  console.log(environment, MerchantApiMappingVn[environment])
  return {
    API_MERCHANT: MerchantApiMappingVn[environment] || 'https://gmerchant.test.now.vn',
    MQTT_HOST: MqttApiMappingVn[environment] || 'https://mqtt.test.now.vn',
  }
}
