import qs from 'qs'
import { AxiosRequestConfig } from 'axios'
import sap from '@shopee/web_enhance_sap'
import { OpenApiBaseUrl } from '@/common/env'
import { isEmptyObject, isFormData, isObject } from './utils'

/**
 * 添加 SAP Header 信息
 * @param config
 * @returns
 */
export const addSapRequestInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const { url = '', method = 'GET', data, params } = config

  // 过滤 OpenApi 接口以及 FormData 数据请求
  if ((data && isFormData(data)) || url.startsWith(OpenApiBaseUrl)) {
    return config
  }

  let body = data
  if (method.toLowerCase() === 'post' && isObject(data)) {
    try {
      body = JSON.stringify(data)
    } catch (error) {
      // EMPTY
    }
  }

  let requestUrl = url
  // 如果有参数，也需要添加到 url 中
  if (!isEmptyObject(params)) {
    const queryString = qs.stringify(params, { sort: (a, b) => a.localeCompare(b) })
    if (queryString.length > 0) {
      if (requestUrl[requestUrl.length - 1] === '?') {
        requestUrl += queryString
      } else if (requestUrl.includes('?')) {
        requestUrl = `${requestUrl}&${queryString}`
      } else {
        requestUrl = `${requestUrl}?${queryString}`
      }
    }
  }

  const signature = sap.generateSignEntry(requestUrl, body)
  Object.assign(config.headers, signature)

  return config
}

/**
 * TSS 需要添加 SPC-B-OFT offlineToken
 * @param config
 * @returns
 */
export const addOfflineTokenInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  if ((window as any)._BaseBridge) {
    const offlineToken = (window as any)._BaseBridge.getAppData().data.user.offlineToken
    if (offlineToken) {
      Object.assign(config.headers, { 'SPC-B-OFT': offlineToken })
    }
  }

  return config
}
