import type { LocaleConfig } from './config/type'
import ID from './config/ID'
import MY from './config/MY'
import TH from './config/TH'
import VN from './config/VN'
import { CidMapKeys } from '@/constants'
import { Region } from '@/common/env'

export let localeConfig: LocaleConfig = ID

export const initLocaleConfig = () => {
  // let region: CidMapKeys = CidMapKeys.id
  // if ((window as any)._BaseBridge) {
  //   region = (window as any)._BaseBridge.getAppData().data.user.region
  // }

  // 根据不同国家，使用不同的配置
  switch (Region) {
    case CidMapKeys.my:
      localeConfig = MY
      break
    case CidMapKeys.th:
      localeConfig = TH
      break
    case CidMapKeys.vn:
      localeConfig = VN
      break
    case CidMapKeys.id:
    default:
      localeConfig = ID
  }
}
