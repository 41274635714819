import { ModalBodySize } from './data'

export function getBodySize(cls: string[], offset: number): ModalBodySize {
  const screenHeight =
    'innerHeight' in window ? window.innerHeight : window.document.documentElement.offsetHeight

  const clsArr = cls.length === 0 ? ['shopee-food-modal'] : [...cls]
  const prefix = clsArr.map((item) => `.${item}`).join('')
  const headerSelector = `${prefix} .shopee-food-modal-header`
  const scrollSelector = `${prefix} .adaptive-modal-scroller-inner`
  const headerSize = window.document.querySelector(headerSelector)?.clientHeight ?? 96
  const scrollerHeight = window.document.querySelector(scrollSelector)?.clientHeight ?? 0
  const bodyHeight = screenHeight - offset * 2 - headerSize
  return {
    height: bodyHeight,
    auto: scrollerHeight < bodyHeight,
  }
}
