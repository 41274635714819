import { TimeStandard, TimezoneOffset, TimezoneOffsetText } from './constants'
import type { LocaleConfig } from './type'

enum TimezoneVN {
  HANOI = 'asia/hanoi',
}

const localeConfig: LocaleConfig = {
  /**
   * timezone
   */
  defaultTimezone: TimezoneVN.HANOI,
  getTimezone: (timezone?: string) => {
    const lowerTimezone = timezone?.toLocaleLowerCase()
    const config = {
      [TimezoneVN.HANOI]: {
        timezoneStandardText: TimeStandard.WITA,
        timezoneOffset: TimezoneOffset.UTC7,
        timezoneOffsetText: TimezoneOffsetText[TimezoneOffset.UTC7],
      },
    }
    return config[lowerTimezone as TimezoneVN]
  },
}

export default localeConfig
