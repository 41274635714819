import actions from '../constants/actionTypes'

export function setCurrentRestaurant(restaurant) {
  return (dispatch) => {
    return dispatch({
      type: actions.SET_CURRENT_RESTAURANT,
      response: restaurant,
    })
  }
}
