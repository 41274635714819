module.exports = {
  // Global App actions
  INIT_SESSION: 'INIT_SESSION',
  FINISH_INIT_APP: 'FINISH_INIT_APP',
  SET_GENERAL_ERROR: 'SET_GENERAL_ERROR',
  CLEAR_GENERAL_ERROR: 'CLEAR_GENERAL_ERROR',
  START_APP_LOADING: 'START_APP_LOADING',
  STOP_APP_LOADING: 'STOP_APP_LOADING',
  CHANGE_GENERAL_CONFIRM_DIALOG: 'CHANGE_GENERAL_CONFIRM_DIALOG',

  // Init App data
  SET_LIST_RESTAURANT: 'SET_LIST_RESTAURANT',

  // Generic actions
  OPEN_DIALOG_GENERIC: 'OPEN_DIALOG_GENERIC',
  CLOSE_DIALOG_GENERIC: 'CLOSE_DIALOG_GENERIC',
  CHANGE_TABLE_PAGE_SIZE: 'CHANGE_TABLE_PAGE_SIZE',
  CHANGE_TABLE_ACTIVE_PAGE: 'CHANGE_TABLE_ACTIVE_PAGE',
  SELECT_TABLE_ITEM: 'SELECT_TABLE_ITEM',
  CHANGE_FILTER_GENERIC: 'CHANGE_FILTER_GENERIC',

  // Setting
  GET_CURRENT_RESTAURANT: 'GET_CURRENT_RESTAURANT',
  SET_CURRENT_RESTAURANT: 'SET_CURRENT_RESTAURANT',

  // Report Dish
  RECEIVE_LIST_REPORT_DISH: 'RECEIVE_LIST_REPORT_DISH',
  CHANGE_FILTER_REPORT_DISH: 'CHANGE_FILTER_REPORT_DISH',

  // Report Restaurant
  RECEIVE_LIST_REPORT_RESTAURANT_INFO: 'RECEIVE_LIST_REPORT_RESTAURANT_INFO',
  RECEIVE_LIST_REPORT_RESTAURANT_IDS: 'RECEIVE_LIST_REPORT_RESTAURANT_IDS',
  OPEN_TRANSACTION_REPORT_RESTAURANT: 'OPEN_TRANSACTION_REPORT_RESTAURANT',
  RECEIVE_TRANSACTION_REPORT_RESTAURANT: 'RECEIVE_TRANSACTION_REPORT_RESTAURANT',
  CHANGE_TABLE_ACTIVE_PAGE_TRANSACTION: 'CHANGE_TABLE_ACTIVE_PAGE_TRANSACTION',
  CHANGE_TABLE_PAGE_SIZE_TRANSACTION: 'CHANGE_TABLE_PAGE_SIZE_TRANSACTION',
  CHANGE_FILTER_TRANSACTION: 'CHANGE_FILTER_TRANSACTION',

  // Export Restaurant
  RECEIVE_LIST_EXPORT_RESTAURANT: 'RECEIVE_LIST_EXPORT_RESTAURANT',
  RECEIVE_ITEM_EXPORT_RESTAURANT: 'RECEIVE_ITEM_EXPORT_RESTAURANT',
}
