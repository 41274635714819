import { ErrorCode } from './request.codes'
export const ErrorCodeMessageMappingAuth = {
  // 3004
  // auth_failed
  // 鉴权失败
  [ErrorCode.AuthFailed]: 'Invalid field, please check',
  // 1130001
  // not login
  // token过期，需要重新登录
  [ErrorCode.NotLogin]: 'Login is required',
  // 1130002
  // need to select a store
  // 需要选择具体一个store
  [ErrorCode.NeedToSelectStore]: 'Login is required',
  // 1130003
  // merchant/store belongs to token/entity_id is not found
  // 没找到属于token/entity_id的商家或店铺
  [ErrorCode.TokenOrEntityIdNotFound]: 'Login is required',
}
export const ErrorCodeMessageListAuth = Object.keys(ErrorCodeMessageMappingAuth).map((i) =>
  parseInt(i, 10),
)

export const ErrorCodeMessageMapping = {
  ...ErrorCodeMessageMappingAuth,
}
