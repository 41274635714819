import moment from 'moment-timezone'

export function generateHeaders() {
  const headers = { 'Content-Type': 'application/json', 'a-token': process.env.APP_HEADER_TOKEN }

  return headers
}

export const CookieUtil = {
  get: function (name: string) {
    const cookieName = `${encodeURIComponent(name)}=`,
      cookieStart = document.cookie.indexOf(cookieName)
    let cookieValue = null

    if (cookieStart > -1) {
      let cookieEnd = document.cookie.indexOf(';', cookieStart)
      if (cookieEnd === -1) {
        cookieEnd = document.cookie.length
      }
      cookieValue = decodeURIComponent(
        document.cookie.substring(cookieStart + cookieName.length, cookieEnd),
      )
    }

    return cookieValue
  },
  set: function ({
    name,
    value,
    expires,
    path,
    domain,
    secure,
  }: {
    name: string
    value: string
    expires?: Date
    path?: string
    domain?: string
    secure?: boolean
  }) {
    let cookieText = `${name}=${value}`
    if (expires instanceof Date) {
      cookieText += `; expires=${expires.toUTCString()}`
    }
    if (path) {
      cookieText += `; path=${path}`
    }
    if (domain) {
      cookieText += `; domain=${domain}`
    }
    if (secure) {
      cookieText += '; secure'
    }
    document.cookie = cookieText
  },
  unset: function (name: string, path: string, domain?: string, secure?: boolean) {
    // 删除，name必须
    this.set({ name, value: '', expires: new Date(0), path, domain, secure })
  },
}

export const formatTimestamp = (
  timestamp: string | number,
  timezone: string,
  formatType: string = 'DD MMM YYYY HH:mm',
) => {
  return moment(Number(timestamp)).tz(timezone).format(formatType)
}
