import actions from '../../../constants/actionTypes'
import { trans } from '../../../localization'

export function setGeneralError(errorMessage, extraData = {}) {
  return {
    type: actions.SET_GENERAL_ERROR,
    response: {
      errorMessage,
      errorExtraData: extraData,
    },
  }
}

export function setGeneralSuccess(errorMessage, extraData) {
  return {
    type: actions.SET_GENERAL_ERROR,
    response: {
      errorMessage,
      errorExtraData: {
        title: trans('common_notification'),
        width: '600px',
        ...extraData,
      },
    },
  }
}

export function clearGeneralError() {
  return {
    type: actions.CLEAR_GENERAL_ERROR,
  }
}

export function startConfirm({
  onCancel = () => {},
  onOk = () => {},
  title = 'Confirm',
  message = trans('mod_reject_are_you_sure'),
}) {
  return {
    type: actions.CHANGE_GENERAL_CONFIRM_DIALOG,
    response: {
      isOpen: true,
      title,
      message,
      onOk,
      onCancel,
    },
  }
}

export function clearConfirm() {
  return {
    type: actions.CHANGE_GENERAL_CONFIRM_DIALOG,
    response: {
      isOpen: false,
    },
  }
}
