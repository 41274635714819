export enum OrderStatusType {
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  Processing = 'Processing',
}

export enum TransactionType {
  deliveryOrder = 5,
  selfPickup = 11,
}
