import { Spin } from 'antd'
import React from 'react'
import styled from 'styled-components'

const PageLoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PageLoading: React.FC = () => {
  return (
    <PageLoadingContainer>
      <Spin size="large" />
    </PageLoadingContainer>
  )
}

export default PageLoading
