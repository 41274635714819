import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import type { I18nT } from '@/assets/strings/i18n/i18n'
import en from '@/assets/strings/i18n/en.json'
import id from '@/assets/strings/i18n/id.json'
import msMy from '@/assets/strings/i18n/ms-my.json'
import zhHans from '@/assets/strings/i18n/zh-Hans.json'
import zhHant from '@/assets/strings/i18n/zh-Hant.json'
import th from '@/assets/strings/i18n/th.json'
import vi from '@/assets/strings/i18n/vi.json'

// 每个国家对应的语言 - MS
// const transifyFileMap = {
//   id: ['en', 'id'],
//   my: ['en', 'ms', 'zh-my'],
//   ph: ['en', 'ph', 'zh-Hans'],
//   sg: ['en', 'zh-Hans'],
//   tw: ['en', 'zh-Hant', 'zh-Hans'],
//   th: ['en', 'th'],
//   vn: ['en', 'vi'],
// }

enum LanguageMapKeys {
  en = 'en',
  id = 'id',
  'ms-my' = 'msMy',
  'zh-my' = 'zhHans',
  'zh-Hans' = 'zhHans',
  'zh-Hant' = 'zhHant',
  th = 'th',
  vi = 'vi',
}

const resources = {
  [LanguageMapKeys.en]: {
    translation: en,
  },
  [LanguageMapKeys.id]: {
    translation: id,
  },
  [LanguageMapKeys['ms-my']]: {
    translation: msMy,
  },
  [LanguageMapKeys['zh-Hans']]: {
    translation: zhHans,
  },
  [LanguageMapKeys['zh-Hant']]: {
    translation: zhHant,
  },
  [LanguageMapKeys.th]: {
    translation: th,
  },
  [LanguageMapKeys.vi]: {
    translation: vi,
  },
}

export const initLanguage = (language: keyof typeof LanguageMapKeys) => {
  i18n.use(initReactI18next).init({
    resources,
    lng: LanguageMapKeys[language] || LanguageMapKeys.en,
    fallbackLng: LanguageMapKeys.en,
    interpolation: {
      prefix: '{',
      suffix: '}',
    },
  })
}

export const t = i18n.t.bind(i18n) as I18nT

export default i18n
