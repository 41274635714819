import actions from '../../constants/actionTypes'
import { getDate } from '../../utils/utils'
import { COMMON_CONST } from '../../constants/constants'
import {
  handleChangeFilters,
  handleChangeTableActivePage,
  handleChangeTablePageSize,
  handleCloseDialogGeneric,
  handleOpenDialogGeneric,
} from '../../redux/genericReducerHandler'
import { EnumMerchantPaidMethod, EnumPage, EnumTransactionStatus } from '../../constants/enum'
import { trans } from '../../localization'

const initialState = {
  filters: {
    fromDate: getDate(-6),
    toDate: getDate(),
    restaurant: {
      value: 0,
      label: trans('mod_all_restaurant'),
    },
    isShowTotal: false,
  },
  table: {
    itemList: [],
    itemData: {},
    activePage: 1,
    pageSize: COMMON_CONST.PageSize,
  },
  dialog: {
    isOpenDialog: false,
    filters: {
      fromDate: getDate(-6),
      toDate: getDate(),
      restaurant: {},
      transactionStatus: EnumTransactionStatus.SUCCESS.id,
      merchantPaidMethod: EnumMerchantPaidMethod.COD.id,
    },
    table: {
      itemList: [],
      totalSize: 0,
      activePage: 1,
      pageSize: COMMON_CONST.PageSize,
    },
  },
}

function restaurantReportReducer(state = initialState, action) {
  const PAGE = EnumPage.REPORT_RESTAURANT.id
  const { response, type } = action
  switch (type) {
    case actions.OPEN_DIALOG_GENERIC: {
      return handleOpenDialogGeneric(PAGE, state, action)
    }
    case actions.CLOSE_DIALOG_GENERIC: {
      return handleCloseDialogGeneric(PAGE, state, action)
    }
    case actions.CHANGE_TABLE_PAGE_SIZE: {
      return handleChangeTablePageSize(PAGE, state, action)
    }
    case actions.CHANGE_TABLE_ACTIVE_PAGE: {
      return handleChangeTableActivePage(PAGE, state, action)
    }
    case actions.CHANGE_FILTER_GENERIC: {
      return handleChangeFilters(PAGE, state, action, initialState)
    }

    case actions.RECEIVE_LIST_REPORT_RESTAURANT_IDS: {
      const { itemList } = response
      return {
        ...state,
        table: {
          ...state.table,
          itemList,
          itemData: {},
          activePage: 1,
        },
      }
    }

    case actions.RECEIVE_LIST_REPORT_RESTAURANT_INFO: {
      const { itemData } = response
      return {
        ...state,
        table: {
          ...state.table,
          itemData: {
            ...state.table.itemData,
            ...itemData,
          },
        },
      }
    }

    case actions.OPEN_TRANSACTION_REPORT_RESTAURANT: {
      const { filters } = response
      return {
        ...state,
        dialog: {
          ...state.dialog,
          filters: {
            ...state.dialog.filters,
            ...filters,
          },
          table: {
            ...state.dialog.table,
            activePage: 1,
          },
        },
      }
    }

    case actions.RECEIVE_TRANSACTION_REPORT_RESTAURANT: {
      const { itemList } = response
      return {
        ...state,
        dialog: {
          ...state.dialog,
          isOpenDialog: true,
          table: {
            ...state.dialog.table,
            itemList,
          },
        },
      }
    }

    case actions.CHANGE_TABLE_ACTIVE_PAGE_TRANSACTION: {
      const { activePage } = response
      return {
        ...state,
        dialog: {
          ...state.dialog,
          table: {
            ...state.dialog.table,
            activePage,
          },
        },
      }
    }

    case actions.CHANGE_TABLE_PAGE_SIZE_TRANSACTION: {
      const { pageSize } = response
      return {
        ...state,
        dialog: {
          ...state.dialog,
          table: {
            ...state.dialog.table,
            pageSize,
          },
        },
      }
    }

    case actions.CHANGE_FILTER_TRANSACTION: {
      const mFilters = Object.keys(response).reduce(
        (filters, filterKey) => ({
          ...filters,
          [filterKey]:
            response[filterKey] !== null && response[filterKey] !== undefined
              ? response[filterKey]
              : initialState.filters[filterKey],
        }),
        {},
      )
      return {
        ...state,
        dialog: {
          ...state.dialog,
          filters: {
            ...state.dialog.filters,
            ...mFilters,
          },
        },
      }
    }

    default:
      return state
  }
}

export default restaurantReportReducer
