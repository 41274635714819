import React from 'react'
import ReactDOM from 'react-dom'
import './index.less'
import App from './App'
// import { initSentry } from './sentry'
import { KEYS, RequestFromType, getCookie, setCookies } from './utils/auth'
import { initLanguage } from '@/utils/i18n'
import { initLocaleConfig } from './locale'
import { initBaseUrl, initOpenApiUrl, initRegion } from '@/common/env'

export interface RenderProps {
  container?: HTMLDivElement
  pathPrefix?: string
  baseBridge?: any
}

function getRoot(props: RenderProps) {
  return props.container ? props.container.querySelector('#root') : document.querySelector('#root')
}

function render(props: RenderProps) {
  const root = getRoot(props)
  const { baseBridge } = props
  let appData
  if (baseBridge) {
    ;(window as any)._BaseBridge = baseBridge
    appData = baseBridge.getAppData()
  }
  // 初始化
  initRegion()
  initLanguage(appData?.data?.user?.language)
  initLocaleConfig()
  initBaseUrl()
  initOpenApiUrl()

  // FIXME: PFB工具设置的pfb domain使用当前网页域名，无法带到foody接口上的问题
  // 有成熟的解决方案以后可以去掉本段逻辑
  if (process.env.APP_ENV !== 'live') {
    const pfb = getCookie('_SPC_PFB') || getCookie('_MSS_PFB')
    if (pfb) {
      setCookies([
        {
          name: '_SPC_PFB',
          value: pfb,
        },
      ])
    }
  }

  if (appData?.data?.user?.language) {
    setCookies([
      {
        name: KEYS.requestToken,
        value: appData ? appData.data.user.shopeeToken : '',
      },
      {
        name: KEYS.requestFrom,
        value: RequestFromType.PartnerWeb,
      },
    ])
  }

  // render
  ReactDOM.render(<App {...props} />, root)
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (!window.__POWERED_BY_QIANKUN__) {
  // initSentry()
  render({})
}

export async function bootstrap() {
  // initSentry()
  console.log('react app bootstraped')
}

export async function mount(props: RenderProps) {
  console.log('props from main framework', props)
  render(props)
}

export async function unmount(props: RenderProps) {
  const root = getRoot(props)
  if (!root) return
  try {
    ReactDOM.unmountComponentAtNode(root)
  } catch (err) {
    console.log(err)
  }
}
