import { TimeStandard, TimezoneOffset, TimezoneOffsetText } from './constants'
import type { LocaleConfig } from './type'

enum TimezoneTH {
  BANGKOK = 'asia/bangkok',
}

const localeConfig: LocaleConfig = {
  /**
   * timezone
   */
  defaultTimezone: TimezoneTH.BANGKOK,
  getTimezone: (timezone?: string) => {
    const lowerTimezone = timezone?.toLocaleLowerCase()
    const config = {
      [TimezoneTH.BANGKOK]: {
        timezoneStandardText: TimeStandard.WIB,
        timezoneOffset: TimezoneOffset.UTC9,
        timezoneOffsetText: TimezoneOffsetText[TimezoneOffset.UTC9],
      },
    }
    return config[lowerTimezone as TimezoneTH]
  },
}

export default localeConfig
