import { Region } from '@/common/env'
import { CidMap } from '@/constants'
import BigNumber from 'bignumber.js'

// 为了避免精度丢失，后端保存数字是实际数值的十万倍
export const PricePrecision = '100000'

// 货币
export const currencyFormatMap = {
  [CidMap.id]: {
    locale: 'id-ID',
    currency: 'IDR',
    minimumFractionDigits: 0,
  },
  [CidMap.th]: {
    locale: 'th-TH',
    currency: 'THB',
    minimumFractionDigits: 2,
  },
  [CidMap.my]: {
    locale: 'ms-MY',
    currency: 'MYR',
    minimumFractionDigits: 2,
  },
  [CidMap.tw]: {
    locale: 'zh-TW',
    currency: 'TWD',
    minimumFractionDigits: 0,
  },
  [CidMap.vn]: {
    locale: 'vi-VN',
    currency: 'VND',
    minimumFractionDigits: 0,
  },
}

export const getCurrencyFormat = () => {
  const cid = CidMap[Region]
  return currencyFormatMap[cid]
}

export const addPrecision = (price: string | number): string => {
  return new BigNumber(price)
    .times(PricePrecision)
    .decimalPlaces(0, BigNumber.ROUND_FLOOR)
    .toString()
}

export const removePrecision = (price: string | number): string => {
  return new BigNumber(price).div(PricePrecision).toString()
}

interface DisplayPriceProps {
  needCurrency?: boolean
  minimumDigits?: number
  useGrouping?: boolean
}

interface DisplayNumberProps {
  minimumDigits?: number
  useGrouping?: boolean
}

export const displayPrice = (price: string | number, options?: DisplayPriceProps) => {
  const { needCurrency, minimumDigits, useGrouping = true } = options || {}
  const currencyFormat = getCurrencyFormat()

  return parseFloat(price as string).toLocaleString(currencyFormat.locale, {
    style: needCurrency ? 'currency' : 'decimal',
    currency: currencyFormat.currency,
    maximumFractionDigits: 5,
    useGrouping: useGrouping,
    minimumFractionDigits:
      minimumDigits !== undefined ? minimumDigits : currencyFormat.minimumFractionDigits,
  })
}

export const formatPrice = (price: string | number, options?: DisplayPriceProps) => {
  return displayPrice(removePrecision(`${price}`), options).replace(/\s*/g, '')
}

export const getCurrency = (): string =>
  Number(0)
    .toLocaleString(getCurrencyFormat().locale, {
      style: 'currency',
      currency: getCurrencyFormat().currency,
      minimumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim()

// 与产品 yudi.shen 确认数字的展示规则跟金额的展示规则一致
export const formatNumber = (num: string | number, options?: DisplayNumberProps) => {
  const { minimumDigits, useGrouping = true } = options || {}
  const currencyFormat = getCurrencyFormat()

  return parseFloat(num as string)
    .toLocaleString(currencyFormat.locale, {
      style: 'decimal',
      maximumFractionDigits: 5,
      useGrouping,
      minimumFractionDigits:
        minimumDigits !== undefined ? minimumDigits : currencyFormat.minimumFractionDigits,
    })
    .replace(/\s*/g, '')
}
