export function handleOpenDialogGeneric(PAGE, state, action) {
  const { page, response } = action
  const { itemId } = response
  if (page === PAGE) {
    return {
      ...state,
      dialog: {
        ...state.dialog,
        isOpenDialog: true,
        itemId,
      },
    }
  }
  return state
}

export function handleCloseDialogGeneric(PAGE, state, action) {
  const { page } = action
  if (page === PAGE) {
    return {
      ...state,
      dialog: {
        ...state.dialog,
        isOpenDialog: false,
        itemId: 0,
      },
    }
  }
  return state
}

export function handleChangeTablePageSize(PAGE, state, action) {
  const { page, response } = action
  const { pageSize } = response
  if (page === PAGE) {
    return {
      ...state,
      table: {
        ...state.table,
        pageSize,
      },
    }
  }
  return state
}

export function handleChangeTableActivePage(PAGE, state, action) {
  const { page, response } = action
  const { activePage } = response
  if (page === PAGE) {
    return {
      ...state,
      table: {
        ...state.table,
        activePage,
      },
    }
  }
  return state
}

export function handleSelectTableItem(PAGE, state, action) {
  const { page, response } = action
  const { selectedId, isForce } = response
  const id = !isForce && state.table.selectedId === selectedId ? 0 : selectedId
  if (page === PAGE) {
    return {
      ...state,
      table: {
        ...state.table,
        selectedId: id,
      },
    }
  }
  return state
}

export function handleChangeFilters(PAGE, state, action, initialState) {
  const { page, response } = action
  if (page === PAGE) {
    const mFilters = Object.keys(response).reduce(
      (filters, filterKey) => ({
        ...filters,
        [filterKey]:
          response[filterKey] !== null && response[filterKey] !== undefined
            ? response[filterKey]
            : initialState.filters[filterKey],
      }),
      {},
    )
    return {
      ...state,
      filters: {
        ...state.filters,
        ...mFilters,
      },
    }
  }
  return state
}
