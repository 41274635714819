import actions from '../constants/actionTypes'
import request from '../utils/request'
import { isSuccess } from '../utils/utils'
import { setGeneralError } from './components/UniversalModal/actions'
import { setCurrentRestaurant } from '../Setting/actions'

export function finishInitApp() {
  return {
    type: actions.FINISH_INIT_APP,
  }
}

export function startAppLoading() {
  return {
    type: actions.START_APP_LOADING,
  }
}

export function stopAppLoading() {
  return {
    type: actions.STOP_APP_LOADING,
  }
}

async function loopGetRestaurants(page = 1, list = []) {
  const pageSize = 50
  const response = await request.postMerchant(
    '/api/v5/seller/store/get_basic_infos_for_partner_web',
    {
      page,
      page_no: page,
      page_size: pageSize,
      service: 3, // 查询 mart&food 两种类型
    },
  )
  if (isSuccess(response)) {
    const { restaurants = [], total = 0 } = response.data
    list.push(...restaurants)

    /**
     * 由于这个接口需要去调用 MS 的服务，因筛选条件限制问题（MS不支持部分筛选条件），导致此接口分页不稳定
     * 因此于后端（kui.jiang@shopee.com）约定当 total < pageSize 时就停止分页请求
     */
    if (total >= pageSize) {
      await loopGetRestaurants(page + 1, list)
    }

    // 按原始响应结构返回
    return { ...response, data: { total: list.length, restaurants: list } }
  } else {
    return response
  }
}

export function getListRestaurant() {
  return async (dispatch) => {
    dispatch(startAppLoading())
    try {
      const response = await loopGetRestaurants()
      if (isSuccess(response)) {
        const restaurants = response.data.restaurants.sort((a, b) => {
          const nameA = a.name.toLowerCase()
          const nameB = b.name.toLowerCase()
          return nameA.localeCompare(nameB)
        })
        dispatch({
          type: actions.SET_LIST_RESTAURANT,
          response: { restaurants },
        })
        const store = restaurants[0]
        return (
          store &&
          dispatch(
            setCurrentRestaurant({
              label: store.name,
              value: store.restaurantId,
              storeId: store.storeId,
            }),
          )
        )
      }
      return dispatch(setGeneralError(response.result || response.msg))
    } finally {
      dispatch(stopAppLoading())
    }
  }
}

export function initializeApp() {
  return async (dispatch) => {
    try {
      await dispatch(getListRestaurant())
    } finally {
      dispatch(finishInitApp())
    }
  }
}
