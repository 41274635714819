import React from 'react'
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom'
import { RouteConfig } from './interface'
import PageLoading from './PageLoading'
import routeConfig from './config'
import { getRouteMenus } from './utils'

const BlankLayout = React.lazy(
  () => import(/* webpackChunkName: "BlankLayout" */ '@/layouts/BlankLayout'),
)

const renderRouteChildren = (routes: RouteConfig[] = []) => {
  return routes.map(({ children, layout, redirect, component, sideMenu, path, ...rest }) => {
    const props: RouteProps = { path }

    if (children) {
      const Layout = layout ? React.lazy(layout) : BlankLayout
      const childrenRoutes = renderRouteChildren(children)

      const sideMenuComponent = sideMenu ? (
        React.createElement(React.lazy(sideMenu), {
          title: rest.title,
          sideMenu: getRouteMenus(children),
        })
      ) : (
        <></>
      )

      props.exact = false
      props.render = (layoutProps: any) => (
        <React.Suspense fallback={React.createElement(PageLoading)}>
          {React.createElement(
            Layout,
            {
              ...rest,
              ...layoutProps,
              sideMenu: sideMenuComponent,
            },
            <Switch>{childrenRoutes}</Switch>,
          )}
        </React.Suspense>
      )
    } else if (redirect) {
      props.exact = true
      props.render = () => <Redirect to={redirect} />
    } else if (component) {
      const Component = React.lazy(component)

      props.exact = true
      props.render = (routeProps: any) => (
        <React.Suspense fallback={React.createElement(PageLoading)}>
          {React.createElement(Component, { ...rest, ...routeProps })}
        </React.Suspense>
      )
    } else {
      throw new Error('路由配置错误，至少包含 [ children | component | redirect ] 之一')
    }

    return <Route key={path} {...props} />
  })
}

export default function Router() {
  const routes = React.useMemo(() => renderRouteChildren(routeConfig), [])

  return (
    <React.Suspense fallback={React.createElement(PageLoading)}>
      <Switch>{routes}</Switch>
    </React.Suspense>
  )
}
