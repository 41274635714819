import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import GeneralModal from '../../../components/Modal/GeneralModal'
import ConfirmModal from '../../../components/Modal/ConfirmModal'
import { clearConfirm, clearGeneralError } from './actions'

class UniversalModals extends React.Component {
  render() {
    const { universalModal, clearGeneralErrorConnect, clearConfirmConnect } = this.props
    const { error, confirm } = universalModal
    return (
      <React.Fragment>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          closeOnClick
          pauseOnVisibilityChange
          pauseOnHover
        />
        <GeneralModal
          open={error.isShow}
          message={error.message}
          onClose={clearGeneralErrorConnect}
          extraData={error.extraData}
        />
        <ConfirmModal
          open={confirm.isOpen}
          title={confirm.title}
          message={confirm.message}
          onOk={confirm.onOk}
          onCancel={confirm.onCancel}
          onClose={clearConfirmConnect}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  isReady: state.app.isReady,
  universalModal: state.universalModal,
})

const mapDispatchToProps = (dispatch) => ({
  clearGeneralErrorConnect: () => {
    dispatch(clearGeneralError())
  },
  clearConfirmConnect: () => {
    dispatch(clearConfirm())
  },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UniversalModals))
