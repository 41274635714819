import { RouteConfig } from './interface'
import exceptionRoutes from './children/exception'
import storeRoutes from './children/storeRoutes'
import openApiRoutes from './children/openApiRoutes'
import orderRoutes from './children/orderRoutes'

const routes: RouteConfig[] = [
  {
    path: '/',
    children: [
      // #region 兼容历史路由
      // TODO: 此部分已上线到 partner web 上，在 partner web 更新路由配置后可以移除
      {
        path: '/',
        component: () =>
          import(/* webpackChunkName: "Store-Settings" */ '@/pages/ShopeeFoodSettings'),
      },
      {
        path: '/shopeeFood-settings/business-hours-settings',
        component: () =>
          import(
            /* webpackChunkName: "Store-BusinessHours" */ '@/pages/ShopeeFoodSettings/BusinessHours/HoursSettings'
          ),
      },
      {
        path: '/transactionDetail',
        component: () =>
          import(/* webpackChunkName: "transactionDetail" */ '@/pages/TransactionDetail'),
      },
      // #endregion

      ...storeRoutes,
      ...orderRoutes,
      ...openApiRoutes,

      /** 异常页面，必须放到最后 */
      ...exceptionRoutes,
    ],
  },
]

if (process.env.NODE_ENV === 'development') {
  routes.unshift(
    {
      path: '/home',
      component: () => import(/* webpackChunkName: "home" */ '@/pages/Home'),
    },
    {
      path: '/demo',
      component: () => import(/* webpackChunkName: "demo" */ '@/pages/Demo'),
    },
  )
}

export default routes
