import React from 'react'

class Loading extends React.Component {
  render() {
    const { loading } = this.props
    return (
      loading && (
        <div className="now-loading">
          <div className="loading-center">
            <div className="loading-center-absolute">
              <div className="object object_one" />
              <div className="object object_two" />
              <div className="object object_three" />
              <div className="object object_four" />
            </div>
          </div>
        </div>
      )
    )
  }
}

export default Loading
