/**
 * 判断变量是否为对象
 * @param {*} obj
 * @returns
 */
export const isObject = (obj: any) => {
  return obj !== undefined && obj !== null && obj.constructor === Object
}

export function isEmpty(value: any): boolean {
  return (
    typeof value === 'undefined' ||
    Number.isNaN(value) ||
    value === null ||
    (typeof value === 'string' && value.trim() === '')
  )
}

export function isFormData(value: any): boolean {
  return value instanceof FormData
}

export function isEmptyObject(obj: any): boolean {
  if (obj !== null && typeof obj !== 'undefined') return Object.keys(obj).length === 0
  return true
}

export function filterQueryParams(params: any): any {
  const result: any = {}
  Object.keys(params).forEach((key) => {
    const val = params[key]
    // axios 中过滤了 null 和 undefined
    if (val === null || typeof val === 'undefined') {
      return
    }
    result[key] = val
  })

  return result
}
