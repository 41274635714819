export enum TimeStandard {
  UTC = 'UTC',
  WIB = 'WIB',
  WITA = 'WITA',
  WIT = 'WIT',
}

export enum TimezoneOffset {
  UTC7 = 7,
  UTC8 = 8,
  UTC9 = 9,
}

export const TimezoneOffsetText = {
  [TimezoneOffset.UTC7]: 'UTC+7',
  [TimezoneOffset.UTC8]: 'UTC+8',
  [TimezoneOffset.UTC9]: 'UTC+9',
}
