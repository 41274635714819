import { CidMapKeys, CidMap } from '@/constants'

export let Region: CidMapKeys = CidMapKeys.id
export const initRegion = () => {
  if ((window as any)._BaseBridge) {
    Region = (window as any)._BaseBridge.getAppData().data.user.region
  } else {
    Region = (process.env.APP_REGION as CidMapKeys) || CidMapKeys.id
  }
}

export let BaseUrl = '/'
export const initBaseUrl = () => {
  if (process.env.NODE_ENV === 'development' && !(window as any).__POWERED_BY_QIANKUN__) {
    // 让only_dev走webpack-dev-server的proxy
    BaseUrl = window.location.origin
    return
  }
  const region = Region
  const env = process.env.APP_ENV || 'test'
  if (env === 'live') {
    BaseUrl = `https://foody.shopee.${CidMap[region]}`
  } else {
    BaseUrl = `https://foody.${env}.shopee.${CidMap[region]}`
  }
  // return `https://foody.${env}.shopee.tw`
}

export let OpenApiBaseUrl = '/'
export const initOpenApiUrl = () => {
  if (process.env.NODE_ENV === 'development' && !(window as any).__POWERED_BY_QIANKUN__) {
    // 让only_dev走webpack-dev-server的proxy
    OpenApiBaseUrl = window.location.origin
    return OpenApiBaseUrl
  }

  const region = Region
  const env = process.env.APP_ENV || 'test'
  if (env === 'live') {
    OpenApiBaseUrl = `https://food-open.shopee.${CidMap[region]}`
  } else {
    OpenApiBaseUrl = `https://food-open.${env}.shopee.${CidMap[region]}`
  }
}
