import { trans } from '../localization'

export function findEnumById(type, id) {
  const result = Object.keys(type).filter((key) => type[key].id === parseInt(id, 10))
  if (result) {
    return type[result[0]]
  }
  return {}
}

export function genEnumToOption(type) {
  return Object.keys(type).map((item) => ({
    value: type[item].value,
    label: trans(type[item].label),
  }))
}

export function transEnum(object) {
  return {
    ...object,
    label: trans(object.label),
  }
}

export const EnumPage = {
  REPORT_DISH: {
    id: 'REPORT_DISH',
  },
  REPORT_RESTAURANT: {
    id: 'REPORT_RESTAURANT',
  },
  EXPORT_RESTAURANT: {
    id: 'EXPORT_RESTAURANT',
  },
  SETTING: {
    id: 'SETTING',
  },
}

export const EnumPagingMode = {
  NONE: {
    id: 1,
  },
  SELF_PAGING: {
    id: 2,
  },
  PAGING_IDS: {
    id: 3,
  },
  PAGING_OFFSET_LIMIT: {
    id: 4,
  },
}

export const EnumMerchantPaidMethod = {
  COD: {
    id: 1,
    description: 'merchant_paid_method_cod',
    icon: 'icon-money',
    title: 'mod_transaction_cod',
  },
  PAYNOW_CREDIT: {
    id: 2,
    description: 'merchant_paid_method_paynow_credit',
    icon: '',
  },
  BANK_TRANSFER: {
    id: 3,
    description: 'merchant_paid_method_bank_transfer',
    icon: '',
  },
  CYBER_SOURCE: {
    id: 4,
    description: 'merchant_paid_method_cyber_source',
    icon: '',
  },
  NAPAS: {
    id: 5,
    description: 'merchant_paid_method_napas',
    icon: '',
  },
  TOPPAY: {
    id: 6,
    description: 'merchant_paid_method_topay',
    icon: 'icon-merchant-wallet',
    title: 'rebranding.mod_transaction_wallet',
  },
  MOMO: {
    id: 7,
    description: 'merchant_paid_method_momo',
    icon: '',
  },
  VNPAY: {
    id: 8,
    description: 'merchant_paid_method_vn_pay',
    icon: '',
  },
  FDC: {
    id: 9,
    description: 'merchant_paid_method_fdc',
    icon: '',
  },
  PN_CREDIT_CODE: {
    id: 10,
    description: 'merchant_paid_method_pn_credit_code',
    icon: '',
  },
  SACOMBANK_ECOM: {
    id: 11,
    description: 'merchant_paid_method_sacombank_ecom',
    icon: '',
  },
}

export const EnumTransactionStatus = {
  SUCCESS: {
    id: 1,
    description: 'SUCCESS',
  },
  ON_HOLD: {
    id: 10,
    description: 'ON_HOLD',
  },
  FAIL: {
    id: 3,
    description: 'FAIL',
  },
  PENDING: {
    id: 2,
    description: 'PENDING',
  },
}

export const EnumMerchantPermission = {
  ORDER_ALL: {
    id: 1,
    message: 'ORDER_ALL',
  },
  REPORT_ALL: {
    id: 2,
    message: 'REPORT_ALL',
  },
  MENU_ALL: {
    id: 4,
    message: 'MENU_ALL',
  },
  EXPRESS_ALL: {
    id: 8,
    message: 'EXPRESS_ALL',
  },
  ACCOUNT_ALL: {
    id: 16,
    message: 'ACCOUNT_ALL',
  },
  PROMOTION_CREATE: {
    id: 32,
    message: 'PROMOTION_CREATE',
  },
  STAFF_MANAGEMENT: {
    id: 64,
    message: 'STAFF_MANAGEMENT',
  },
  PROMOTION_VIEW: {
    id: 128,
    message: 'PROMOTION_VIEW',
  },
  FEEDBACK_SYSTEM: {
    id: 256,
    message: 'FEEDBACK_SYSTEM',
  },
  FEEDBACK_SHIPPER: {
    id: 512,
    message: 'FEEDBACK_SHIPPER',
  },
  CUSTOMER_ALL: {
    id: 1024,
    message: 'CUSTOMER_ALL',
  },
  WALLET_ALL: {
    id: 2048,
    message: 'WALLET_ALL',
  },
}

export const EnumReportType = {
  ORDER_REPORT: {
    id: 1,
    description: 'mod_report_type_ORDER_REPORT',
  },
}

export const EnumExportReportStatus = {
  PENDING: {
    id: 1,
    description: 'mod_report_status_PENDING',
    message: 'mod_export_restaurant_PENDING',
  },
  PROCESSING: {
    id: 2,
    description: 'mod_report_status_PROCESSING',
    message: 'mod_message_export_PROCESSING',
  },
  FAILED: {
    id: 3,
    description: 'mod_report_status_FAILED',
    message: 'mod_message_export_FAILED',
    color: 'text-danger',
  },
  SUCCESS: {
    id: 4,
    description: 'mod_report_status_SUCCESS',
    message: 'mod_message_export_SUCCESS',
    color: 'text-green',
  },
  EMPTY_DATA: {
    id: 5,
    description: 'mod_report_status_EMPTY_DATA',
    message: 'mod_message_export_EMPTY_DATA',
  },
}

export const EnumTypePushMQTT = {
  MERCHANT_REPORT: {
    id: 1,
    value: 'merchant_report',
  },
}

export const EnumLanguage = {
  VIETNAM: {
    id: 1,
    value: 'vi',
    icon: 'vn',
  },
  ENGLAND: {
    id: 2,
    value: 'en',
    icon: 'us',
  },
}

export const EnumReportTimeFilterType = {
  SUBMITTED_TIME: {
    value: 1,
    label: trans('report_submitted_time'),
  },
  DELIVERED_TIME: {
    value: 2,
    label: trans('report_delivered_time'),
  },
}
export const EnumSortOrder = {
  ASC: { id: 1 },
  DESC: { id: 2 },
}

export const EnumReportRestaurantSortField = {
  RESTAURANT: { id: 1 },
  QUANTITY_ORDER: { id: 2 },
  TOTAL_AMOUNT_BEFORE_DISCOUNT: { id: 3 },
  MERCHANT_DISCOUNT: { id: 4 },
  COMMISSION: { id: 5 },
  TOTAL_AMOUNT: { id: 6 },
}

export const EnumReportDishSortField = {
  DISH: { id: 1 },
  QUANTITY: { id: 2 },
}
