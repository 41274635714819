import actions from '../constants/actionTypes'

const initialState = {
  isReady: false,
  isLoading: false,
  restaurants: [],
}

function appReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FINISH_INIT_APP: {
      return {
        ...state,
        isReady: true,
      }
    }
    case actions.START_APP_LOADING: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case actions.STOP_APP_LOADING: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case actions.SET_LIST_RESTAURANT: {
      const { restaurants } = action.response
      return {
        ...state,
        restaurants,
      }
    }

    default:
      return state
  }
}

export default appReducer
