import { BaseUrl } from '@/common/env'
import { CookieUtil } from '@/common/utils'

export const KEYS = {
  clientToken: 'tob_token',
  clientEntityId: 'WALLET_SESSION_ENTITY_ID',
  requestToken: 'shopee_tob_token',
  requestEntityId: 'shopee_tob_entity_id',
  requestFrom: 'shopee_request_from',
  sessionId: 'shopee_tob_session_id',
  pfb: 'SPC_PFB_FOODY_SELLER',
  entityId: 'currentStoreId',
}

export enum RequestFromType {
  PartnerWeb = 'partner_web',
  PartnerApp = 'partner_app',
}

type CookieType = {
  name: string
  value: string
}
export const setCookies = (cookies: CookieType[], domain = BaseUrl) => {
  cookies.forEach(({ name, value }) => {
    CookieUtil.set({
      name,
      value,
      path: '/',
      domain: domain.split('.').slice(1).join('.'),
    })
  })
}

export const getCookie = (key: string) => {
  if (!key) {
    return ''
  }

  return CookieUtil.get(key)
}
