export const DefaultPageSize = 10
export const DefaultPageNum = 1

export const SessionStorageKeys = {
  MerchantId: 'MerchantId',
}

export enum CidMapKeys {
  id = 'id',
  my = 'my',
  th = 'th',
  sg = 'sg',
  ph = 'ph',
  vn = 'vn',
  tw = 'tw',
  br = 'br',
}
// 国家简称对应的域名变更部分
export const CidMap: Record<CidMapKeys, string> = {
  id: 'co.id',
  my: 'com.my',
  th: 'co.th',
  sg: 'sg',
  ph: 'ph',
  vn: 'vn',
  tw: 'tw',
  br: 'com.br',
}

export enum AppShowType {
  Page = 'page',
  Modal = 'modal',
}

export enum CloseType {
  LOGOUT = 'logout',
}
