import React from 'react'
import Modal, { ModalAction, ModalBody, ModalButton, ModalHeader } from './Modal'
import { trans } from '../../localization'

class ConfirmModal extends React.Component {
  handleActionClick = (callback) => {
    const { onClose } = this.props
    return () => {
      onClose()
      callback()
    }
  }

  render() {
    const { open, title, message, onOk, onCancel } = this.props
    return (
      <Modal isOpen={open} zIndex={3}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <div style={{ margin: 10 }}>{message}</div>
        </ModalBody>
        <ModalAction>
          <ModalButton styleType="red" onClick={this.handleActionClick(onOk)}>
            {trans('common_yes')}
          </ModalButton>
          <ModalButton type="cancel" onClick={this.handleActionClick(onCancel)}>
            {trans('common_close')}
          </ModalButton>
        </ModalAction>
      </Modal>
    )
  }
}

export default ConfirmModal
