import { RouteConfig } from './interface'

export function getRouteMenus(routes: RouteConfig[]) {
  return routes
    .filter((route) => !route.redirect)
    .map((route) => {
      if (route.children) {
        route.children = getRouteMenus(route.children)
      }

      return route
    })
}
