import { RouteConfig } from '../interface'

const exceptionRoutes: RouteConfig[] = [
  {
    path: '/exception/403',
    component: () => import(/* webpackChunkName: "exception" */ '@/pages/exception/403'),
  },
  {
    path: '/exception/404',
    component: () => import(/* webpackChunkName: "exception" */ '@/pages/exception/404'),
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "exception" */ '@/pages/exception/403'),
  },
]

export default exceptionRoutes
