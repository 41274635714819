import React, { Component } from 'react'

class Footer extends Component {
  render() {
    return (
      <footer className="main-footer">
        <div className="container-inner clearfix">
          <div className="float-left">Powered by ShopeeFood</div>
          <div className="float-right">Version 1.0.0</div>
        </div>
      </footer>
    )
  }
}

export default Footer
