import { formatPrice, getCurrency } from '@/utils/price'
import { memo, useMemo } from 'react'
import { PriceProps } from '../../data'
import './index.less'

/**
 * 价格组件，自动处理各国家价格展示
 * style: --unit-size 控制单位字体大小，--price-size 控制金额字体大小
 * @param {PriceProps} { price, block, currency = true, minus, lineThrough, style }
 * @return {*}
 */
const Price = ({ price, block, currency = true, minus, lineThrough, style }: PriceProps) => {
  const cls = useMemo(() => {
    const arr = ['tnx-price']
    if (block) arr.push('block')
    if (lineThrough) arr.push('line-through')
    return arr
  }, [block, lineThrough])

  const CURRENCY = useMemo(() => getCurrency(), [])

  const _price = useMemo(() => {
    if (minus) {
      return `-${formatPrice(price)}`
    }
    return formatPrice(price)
  }, [price, minus])

  return (
    <span className={cls.join(' ')} style={style}>
      {currency && <span className="unit">{CURRENCY}</span>}
      <span className="price">{_price}</span>
    </span>
  )
}

export default memo(Price)
