import { compose, createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import { autoRehydrate } from 'redux-persist'
import thunkMiddleware from 'redux-thunk'

import rootReducer from './rootReducer'

const loggerMiddleware = createLogger({
  predicate: () => process.env.NODE_ENV !== 'production',
})

const composeMiddleware = [
  applyMiddleware(thunkMiddleware),
  applyMiddleware(loggerMiddleware),
  autoRehydrate(),
]

export default createStore(rootReducer, undefined, compose(...composeMiddleware))
