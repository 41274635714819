import React from 'react'
import ReactModal from 'react-modal'
import { ReactSVG } from 'react-svg'

import './Modal.scss'

import CloseSvgIcon from '../../assets/common/svg/close.svg'

export const ModalHeader = ({ children }) => <div className="now-modal-header">{children}</div>

export const ModalBody = ({ children, className = '' }) => (
  <div className={`now-modal-body ${className}`}>{children}</div>
)

export const ModalAction = ({ children }) => <div className="now-modal-action">{children}</div>

export const ModalButton = ({
  children,
  className = '',
  disabled = false,
  type = 'primary',
  styleType = 'white',
  onClick,
}) => (
  <div
    className={`btn-${styleType} now-modal-button now-modal-button-${type} ${className} ${
      disabled ? 'now-modal-button-disabled' : ''
    }`}
    onClick={onClick}
  >
    {children}
  </div>
)

class Modal extends React.Component {
  static defaultProps = {
    width: '50%',
    withOverlay: true,
    withCloseButton: false,
    onCloseButtonClick: () => {},
  }

  render() {
    const { width, withOverlay, withCloseButton, children, zIndex = 2, ...rest } = this.props
    const overlayBackground = withOverlay ? 'rgba(0, 0, 0, 0.7)' : 'rgba(0, 0, 0, 0)'
    return (
      <ReactModal
        ariaHideApp={false}
        closeTimeoutMS={500}
        style={{
          overlay: {
            backgroundColor: overlayBackground,
            overflow: 'auto',
            zIndex,
          },
          content: {
            left: 0,
            right: 0,
            top: 'auto',
            bottom: 'auto',
            marginLeft: 'auto',
            marginRight: 'auto',
            height: 'auto',
            marginTop: 60,
            marginBottom: 60,
            borderRadius: 3,
            padding: 0,
            border: 'none',
            overflow: 'visible',
            width,
          },
        }}
        {...rest}
      >
        {withCloseButton && (
          <div onClick={rest.onRequestClose || rest.onCloseButtonClick} className="now-modal-close">
            <ReactSVG
              aria-hidden="true"
              src={CloseSvgIcon}
              wrapper="span"
              beforeInjection={(svg) => {
                svg.setAttribute('style', `width: 16pxheight:16pxmargin:0 5px 0 0color:#e3e3e3`)
              }}
            />
          </div>
        )}
        {children}
      </ReactModal>
    )
  }
}

export default Modal
