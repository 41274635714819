import qs from 'qs'
import { BaseUrl } from '@/common/env'
import { message as Message } from 'antd'
import type { AxiosRequestConfig } from 'axios'
import { decamelizeKeys, camelizeKeys } from 'humps'
import axios from 'axios'
import { ErrorCodeMessageListAuth, ErrorCodeMessageMapping } from './request.constant'
import { CloseType } from '@/constants'
import { addOfflineTokenInterceptor, addSapRequestInterceptor } from './interceptors'

export const showErrorDialog = async (content: string) => {
  return Message.error(content || 'Network Error')
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const transformURL = (url: string, data: any, baseURL = BaseUrl) => {
  const fullUrl = `${baseURL}${url}`
  if (!data) {
    return { url: fullUrl, data }
  }
  let cloneData = data
  let cloneUrl = fullUrl
  try {
    cloneData = JSON.parse(JSON.stringify(data))
  } catch (error) {}

  Object.entries(cloneData).forEach(([key]) => {
    const reg = new RegExp(`/:${key}`)
    if (reg.test(cloneUrl)) {
      cloneUrl = cloneUrl.replace(`/:${key}`, `/${cloneData[key]}`)
      delete cloneData[key]
    }
  })

  return { url: cloneUrl, data: cloneData }
}

type RequestConfig = AxiosRequestConfig | string

export const instance = axios.create({
  withCredentials: true,
  paramsSerializer: (params) => qs.stringify(params, { sort: (a, b) => a.localeCompare(b) }),
})

instance.interceptors.response.use(
  (res) => {
    const { data } = res
    const { code } = data
    const camelizedData = camelizeKeys(res)

    if (ErrorCodeMessageListAuth.includes(code)) {
      // If tob token error, redirect to login page, no error throw
      const baseBridge = (window as any)._BaseBridge
      if (baseBridge) {
        const { onClose } = baseBridge.getAppData()
        onClose && onClose({ closeType: CloseType.LOGOUT })
        showErrorDialog(ErrorCodeMessageMapping[code])
      }
    }
    return camelizedData
  },
  (error) => {
    return Promise.reject(error)
  },
)

instance.interceptors.request.use(addSapRequestInterceptor)
instance.interceptors.request.use(addOfflineTokenInterceptor)

instance.interceptors.request.use(
  (config) => {
    const { data, params } = config
    const options = {
      ...config,
      data: data ? { ...decamelizeKeys(data) } : data,
      params: params ? { ...decamelizeKeys(params) } : params,
    }
    return options
  },
  (error) => {
    return Promise.reject(error)
  },
)

const request = (config: RequestConfig) => {
  let params = config
  if (typeof config === 'object') {
    const axiosConfig = config as AxiosRequestConfig
    const { data, url } = transformURL(
      axiosConfig.url as string,
      axiosConfig.data,
      axiosConfig.baseURL,
    )
    params = {
      ...axiosConfig,
      data,
      url,
    }

    if ((!params.method || params.method.toLocaleUpperCase() === 'GET') && !params.params) {
      params.params = data
    }
  }

  return (
    typeof config === 'object' ? instance(params as AxiosRequestConfig) : instance(params as string)
  )
    .then((response) => {
      const { code } = response.data
      if (code === 0) {
        return Promise.resolve(response.data)
      }
      return Promise.reject({ response })
    })
    .catch(async (error) => {
      const { response } = error
      let message
      let statusCode = 0
      let code = 0

      if (response && response instanceof Object) {
        const { data, statusText } = response
        statusCode = response.status
        code = response.code
        message = data.msg || statusText
      } else {
        // eslint-disable-next-line prefer-destructuring
        message = error.message
        code = error.code
      }
      return Promise.reject({
        statusCode,
        code,
        message,
      })
    })
}

export default request
