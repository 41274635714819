import { combineReducers } from 'redux'
import actions from '../constants/actionTypes'
import appReducer from '../layout/reducers'
import universalReducer from '../layout/components/UniversalModal/reducers'
import settingsReducer from '../Setting/reducers'
import dishReportReducer from '../views/ReportDish/reducers'
import restaurantReportReducer from '../views/ReportRestaurant/reducers'
import exportRestaurantReducer from '../views/ExportRestaurant/reducers'

const combineReducer = combineReducers({
  app: appReducer,
  universalModal: universalReducer,
  auth: {},
  setting: settingsReducer,
  report: combineReducers({
    dish: dishReportReducer,
    restaurant: restaurantReportReducer,
    export: exportRestaurantReducer,
  }),
})

const rootReducer = (state, action) => {
  let newState = state
  if (action.type === actions.INIT_SESSION) {
    newState = undefined
  }
  return combineReducer(newState, action)
}

export default rootReducer
