import { memo, useMemo } from 'react'
import { StatusTagProps } from '../../data'
import './index.less'

const StatusTag = ({ status }: StatusTagProps) => {
  const _className = useMemo(() => {
    return ['txn-status-tag', status.className].join(' ')
  }, [status])

  return <span className={_className}>{status.text}</span>
}

export default memo(StatusTag)
