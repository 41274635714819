import moment from 'moment'
import { COMMON_CONST, SUCCESS_RESPONSE, DEFAULT_FILE_NAME } from '../constants/constants'

/**
 * 判断变量是否为对象
 * @param {*} obj
 * @returns
 */
export const isObject = (obj) => {
  return obj !== undefined && obj !== null && obj.constructor === Object
}

export function isEmpty(value) {
  return value === undefined || value === null || value === ''
}

export function isEmptyObject(obj) {
  if (obj !== null && obj !== undefined) return Object.keys(obj).length === 0
  return true
}

export function isFormData(value: any): boolean {
  return value instanceof FormData
}

export function isSuccess(response) {
  return response && (response.result === SUCCESS_RESPONSE || response.msg === SUCCESS_RESPONSE)
}

export function getParameterByName(rawName, rawUrl) {
  let url = rawUrl
  if (!rawUrl) {
    url = window.location.href
  }
  const name = rawName.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url)
  if (!results) {
    return null
  }
  if (!results[2]) {
    return ''
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export function validateEmail(email) {
  if (!isEmpty(email)) {
    // eslint-disable-next-line max-len
    const re = /^[a-zA-Z0-9.]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    return re.test(String(email).toLowerCase())
  }
  return true
}

export function formatCurrency(value, unit = '₫', locale = 'en-US', digit = 0) {
  const v = parseFloat(value).toLocaleString(locale, {
    minimumFractionDigits: digit,
  })
  return unit ? `${v}${unit}` : `${v}`
}

export function getDate(offset = 0) {
  return moment().add(offset, 'days').toDate()
}

export function parseDate(date) {
  return moment(date, COMMON_CONST.FormatDate).toDate()
}

export function formatDate(date) {
  return moment(date).format(COMMON_CONST.FormatDate)
}

export function parseDateTime(date) {
  return moment(date, COMMON_CONST.FormatDateTime).toDate()
}

export function formatDateView(date) {
  return moment(date).format(COMMON_CONST.FormatDayView)
}

export function formatDateTime(date) {
  return moment(date).format(COMMON_CONST.FormatDateTime)
}

export function formatDateTimeView(date) {
  return moment(date).format(COMMON_CONST.FormatDateView)
}

export function formatTimeDateView(date) {
  return moment(date).format(COMMON_CONST.FormatTimeDateView)
}

export function formatStartDateTime(date) {
  return moment(date).startOf('day').format(COMMON_CONST.FormatDateTime)
}

export function formatEndDateTime(date) {
  return moment(date).endOf('day').format(COMMON_CONST.FormatDateTime)
}

export function formatTSTimeDateView(date) {
  return moment.unix(date).format(COMMON_CONST.FormatTimeDateView)
}

// Set time (format HH:mm:ss|HH:mm) and keep the date
export function setTime(date, timeString) {
  const [hour, minute, second = 0] = timeString.split(':')
  return moment(date).set({ hour, minute, second }).toDate()
}

export function parseToInt(value) {
  return parseInt(value, 10)
}

export function mathRoundNumber(value) {
  return Math.round(value)
}

export function getIdsPaging(itemIds, activePage, pageSize) {
  const pageIndex = activePage - 1
  return !isEmpty(itemIds)
    ? itemIds.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize)
    : []
}

export function getIdsPagingMaps(itemList, activePage, pageSize) {
  const itemIds = !isEmpty(itemList) ? Array.from(itemList.keys()) : []
  return getIdsPaging(itemIds, activePage, pageSize)
}

export function numberToArray(count, start = 1) {
  return [...Array(count - start).keys()].map((item) => item + start)
}

export function decodeUtf8(arrayBuffer) {
  return new TextDecoder('utf-8').decode(arrayBuffer)
}

export async function requestNotificationPermission() {
  // Check if the browser supports notifications
  if ('Notification' in window && Notification.permission !== 'denied') {
    return Notification.requestPermission()
  }
  return 'denied'
}

export function validatePhone(phone) {
  const phoneRegVN = /^0[0-9]{9,10}$/
  return phoneRegVN.test(phone)
}

export function clearSpecialCharacters(value = '') {
  // eslint-disable-next-line
  return value.replace(/[` ~!@#$%^&*()_|+\-=?:'",.<>\{\}\[\]\\\/]/gi, '')
}

export function getLocalStore(key) {
  return JSON.parse(localStorage.getItem(key))
}

export function setLocalStore(key, value) {
  localStorage.setItem(key, JSON.stringify(value))
}

export function getPermissionRestaurant(restaurants, role) {
  return restaurants.filter(
    (item) => item.userPermissionFlag === undefined || item.userPermissionFlag & role,
  )
}

export function downloadFileExport(fileUrl, fileName = DEFAULT_FILE_NAME) {
  const link = document.createElement('a')
  link.href = fileUrl
  link.style = 'display: none'
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export function convertPriceToValue(value = '', removeParam = 'đ') {
  return mathRoundNumber(value.replace(/[,đ,$]/g, '').replace(removeParam, ''))
}

export function getReactSelectValue(availableOptions, currentVal) {
  return availableOptions.find((option) => option.value === currentVal)
}
