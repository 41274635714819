import { message as Message } from 'antd'
import { CheckCircleFilled, CloseOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import type { ArgsProps } from 'antd/es/notification'
import './style.less'

enum NotifyType {
  error = 'error',
  success = 'success',
}

type Params = Pick<ArgsProps, 'message' | 'duration' | 'onClose'> & {
  title?: string
  type?: NotifyType
}

export const bridge = (type: NotifyType, { title, message, ...rest }: Params) => {
  // eslint-disable-next-line prefer-const
  let hide: (() => void) | undefined
  const onClose = () => hide?.()

  const Icon = () => {
    if (type === NotifyType.error) return <ExclamationCircleFilled className="notifyIcon" />
    if (type === NotifyType.success) return <CheckCircleFilled className="notifyIcon" />
    return null
  }

  hide = Message.info({
    className: `customNotify ${type}`,
    content: (
      <div className="notifyBlock">
        <div className="notifyLeft">
          <Icon />
        </div>
        <div className="notifyCenter">
          <p className="notifyTitle">{title}</p>
          <p className="notifyMessage">{message}</p>
        </div>
        <div className="notifyRight" onClick={onClose}>
          <CloseOutlined className="notifyCloseIcon" />
        </div>
      </div>
    ),
    duration: 5,
    ...rest,
  })
}

// 统一在一处管理成功/失败之后的信息通知
export const notify = {
  error: (params: Params) => bridge(NotifyType.error, params),
  success: (params: Params) => bridge(NotifyType.success, params),
}
