import { RouteConfig } from '../interface'

const openApiRoutes: RouteConfig[] = [
  {
    title: 'Integration Tools',
    path: '/open-api',
    layout: () => import(/* webpackChunkName: "PageLayout" */ '@/layouts/PageLayout'),
    sideMenu: () =>
      import(/* webpackChunkName: "SubMenuOnly"*/ '@/layouts/components/SideMenus/SubMenuOnly'),
    className: 'open-api-layout',
    children: [
      {
        path: '/open-api',
        redirect: '/open-api/check-signature',
      },
      {
        title: 'Check Signature',
        path: '/open-api/check-signature',
        component: () =>
          import(/* webpackChunkName: "OpenApi-CheckSignature" */ '@/pages/OpenApi/CheckSignature'),
      },
      {
        title: 'Submit Order',
        path: '/open-api/order-submit',
        component: () =>
          import(/* webpackChunkName: "OpenApi-OrderSubmit" */ '@/pages/OpenApi/OrderSubmit'),
      },
      {
        title: 'Update Order',
        path: '/open-api/order-update',
        component: () =>
          import(/* webpackChunkName: "OpenApi-OrderUpdate" */ '@/pages/OpenApi/OrderUpdate'),
      },
    ],
  },
]

export default openApiRoutes
