import { RouteConfig } from '../interface'

const orderRoutes: RouteConfig[] = [
  {
    path: '/order',
    children: [
      {
        path: '/order',
        redirect: '/order/report-restaurant',
      },
      {
        path: '/order/report-restaurant',
        component: () =>
          import(
            /* webpackChunkName: "Report-ReportRestaurant" */ '@/pages/Order/views/ReportRestaurant/ReportRestaurant'
          ),
      },
      {
        path: '/order/export-restaurant',
        component: () =>
          import(
            /* webpackChunkName: "Report-ExportRestaurant" */ '@/pages/Order/views/ExportRestaurant/ExportRestaurant'
          ),
      },
      {
        path: '/order/report-dish',
        component: () =>
          import(
            /* webpackChunkName: "Report-ReportDish" */ '@/pages/Order/views/ReportDish/ReportDish'
          ),
      },
    ],
  },
]

export default orderRoutes
