export const ErrorCodeAuth = {
  // auth_failed
  // 鉴权失败
  AuthFailed: 3004,
  // not login
  // token过期，需要重新登录
  NotLogin: 1130001,
  // need to select a store
  // 需要选择具体一个store
  NeedToSelectStore: 1130002,
  // merchant/store belongs to token/entity_id is not found
  // 没找到属于token/entity_id的商家或店铺
  TokenOrEntityIdNotFound: 1130003,
}

export const ErrorCode = {
  ...ErrorCodeAuth,
}
