import { RouteConfig } from '../interface'

const storeRoutes: RouteConfig[] = [
  {
    path: '/store',
    children: [
      {
        path: '/store',
        redirect: '/store/setting',
      },
      {
        path: '/shopeeFood-settings/business-hours-settings',
        redirect: '/store/business-hours',
      },
      {
        path: '/store/setting',
        component: () =>
          import(/* webpackChunkName: "Store-Settings" */ '@/pages/ShopeeFoodSettings'),
      },
      {
        path: '/store/setting/business-hours',
        component: () =>
          import(
            /* webpackChunkName: "Store-BusinessHours" */ '@/pages/ShopeeFoodSettings/BusinessHours/HoursSettings'
          ),
      },
    ],
  },
]

export default storeRoutes
