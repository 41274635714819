export const PAGE_SIZE_OPTIONS = [5, 10, 25, 50]

export const MAX_SHOW_PAGING_PAGE = 3

export const SLEEP_TIMEOUT = 200 // ms

export const COMMON_CONST = {
  PageSize: 20,
  FormatDayView: 'DD-MM-YYYY',
  FormatDateView: 'DD/MM/YYYY - HH:mm',
  FormatDateTimeView: 'DD/MM/YYYY - hh:mm A',
  FormatDateTypeView: 'd/m/Y',
  FormatDateTimeTypeView: 'd/m/Y - H:i',
  FormatDate: 'YYYY-MM-DD',
  FormatDateTime: 'YYYY-MM-DD HH:mm:ss',
  FormatPhoneNumber: '#### ### ###',
  FormatTimeDateView: 'HH:mm - DD/MM/YYYY',
}

export const IGNORE_ERROR = true

export const MAX_LENGTH_IDS_REQUEST = 100

export const DEFAULT_FILE_NAME = 'download.xls'

export const ERROR_ACCESS_TOKEN_CODE = 'error_access_token'

export const ERROR_PARAMS = 'error_params'

export const SUCCESS_RESPONSE = 'success'

export const NOW_ORDER_MQTT_TOPIC = 'delil/'
