import actions from '../constants/actionTypes'
import { handleCloseDialogGeneric } from '../redux/genericReducerHandler'
import { EnumLanguage, EnumPage } from '../constants/enum'

const initialState = {
  language: EnumLanguage.VIETNAM.value,
  currentRestaurant: {},
  isChangeRestaurant: false,
}

function settingsReducer(state = initialState, action) {
  const { response, type } = action
  const PAGE = EnumPage.SETTING.id
  switch (type) {
    case actions.CLOSE_DIALOG_GENERIC: {
      return handleCloseDialogGeneric(PAGE, state, action)
    }
    case actions.GET_CURRENT_RESTAURANT: {
      const { isShow } = response
      return {
        ...state,
        isChangeRestaurant: isShow,
      }
    }
    case actions.SET_CURRENT_RESTAURANT: {
      return {
        ...state,
        currentRestaurant: {
          name: response.label,
          restaurantId: response.value,
          storeId: response.storeId || state.currentRestaurant.storeId,
        },
      }
    }
    default:
      return state
  }
}

export default settingsReducer
