import actions from '../../constants/actionTypes'
import { getDate } from '../../utils/utils'
import { COMMON_CONST } from '../../constants/constants'
import {
  handleChangeTableActivePage,
  handleChangeTablePageSize,
} from '../../redux/genericReducerHandler'
import { EnumPage } from '../../constants/enum'
import { trans } from '../../localization'

const initialState = {
  filters: {
    fromDate: getDate(-6),
    toDate: getDate(),
    restaurant: {
      value: 0,
      label: trans('mod_all_restaurant'),
    },
    isShowChart: false,
  },
  table: {
    itemList: [],
    totalSize: 0,
    activePage: 1,
    pageSize: COMMON_CONST.PageSize,
  },
}

function dishReportReducer(state = initialState, action) {
  const PAGE = EnumPage.REPORT_DISH.id
  const { response, type } = action
  switch (type) {
    case actions.CHANGE_TABLE_PAGE_SIZE: {
      return handleChangeTablePageSize(PAGE, state, action)
    }
    case actions.CHANGE_TABLE_ACTIVE_PAGE: {
      return handleChangeTableActivePage(PAGE, state, action)
    }
    case actions.RECEIVE_LIST_REPORT_DISH: {
      const { itemList, totalSize } = response
      return {
        ...state,
        table: {
          ...state.table,
          itemList,
          totalSize,
        },
      }
    }
    case actions.CHANGE_FILTER_REPORT_DISH: {
      const mFilters = Object.keys(response).reduce(
        (filters, filterKey) => ({
          ...filters,
          [filterKey]:
            response[filterKey] !== null && response[filterKey] !== undefined
              ? response[filterKey]
              : initialState.filters[filterKey],
        }),
        {},
      )
      return {
        ...state,
        filters: {
          ...state.filters,
          ...mFilters,
        },
      }
    }
    default:
      return state
  }
}

export default dishReportReducer
