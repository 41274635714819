import { TimeStandard, TimezoneOffset, TimezoneOffsetText } from './constants'
import type { LocaleConfig } from './type'

enum TimezoneMY {
  KUALALUMPUR = 'asia/kuala_lumpur',
}

const localeConfig: LocaleConfig = {
  /**
   * timezone
   */
  defaultTimezone: TimezoneMY.KUALALUMPUR,
  getTimezone: (timezone?: string) => {
    const lowerTimezone = timezone?.toLocaleLowerCase()
    const config = {
      [TimezoneMY.KUALALUMPUR]: {
        timezoneStandardText: TimeStandard.WITA,
        timezoneOffset: TimezoneOffset.UTC8,
        timezoneOffsetText: TimezoneOffsetText[TimezoneOffset.UTC8],
      },
    }
    return config[lowerTimezone as TimezoneMY]
  },
}

export default localeConfig
