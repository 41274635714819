import qs from 'qs'
import axios from 'axios'
import { decamelizeKeys, camelizeKeys } from 'humps'

import { addSapRequestInterceptor, addOfflineTokenInterceptor } from './interceptors'
import store from '../redux/store'
import { setGeneralError } from '../layout/components/UniversalModal/actions'
import { ERROR_ACCESS_TOKEN_CODE, ERROR_PARAMS } from '../constants/constants'
import { stopAppLoading } from '../layout/actions'
import { getConfig } from '../configs/index'

const instance = axios.create({
  withCredentials: true,
  paramsSerializer: (params) => qs.stringify(params, { sort: (a, b) => a.localeCompare(b) }),
})

instance.interceptors.response.use(
  (res) => {
    const camelizedData = camelizeKeys(res)

    return camelizedData
  },
  (error) => {
    return Promise.reject(error)
  },
)

instance.interceptors.request.use(addSapRequestInterceptor)
instance.interceptors.request.use(addOfflineTokenInterceptor)

instance.interceptors.request.use(
  (config) => {
    const { data, params } = config
    const options = {
      ...config,
      data: data ? { ...decamelizeKeys(data) } : data,
      params: params ? { ...decamelizeKeys(params) } : params,
    }
    return options
  },
  (error) => {
    return Promise.reject(error)
  },
)

class Request {
  static header(extraHeaders) {
    const {
      data: { user },
    } = window._BaseBridge?.getAppData() || { data: { user: {} } }
    const storeId = store.getState()?.setting?.currentRestaurant?.storeId
    const headers = {
      'x-foody-client-id': 'ffffffff-c9a4-034c-ffff-ffffc2e834d9',
      'x-foody-client-type': '1',
      'x-foody-app-type': '1025',
      'x-foody-client-version': '3.0.0',
      'x-foody-api-version': '1',
      'x-foody-client-language': store.getState()?.setting?.language || 'vi',
      'x-foody-access-token': user.shopeeToken,
    }
    if (storeId) {
      headers['x-foody-entity-id'] = storeId
    }
    return {
      ...headers,
      ...extraHeaders,
    }
  }

  static resolveResponse() {
    return (respAxios) => {
      const { data: response } = respAxios
      if (
        response.data === null &&
        (response.result === ERROR_ACCESS_TOKEN_CODE || response.msg === ERROR_ACCESS_TOKEN_CODE)
      ) {
        const baseBridge = window._BaseBridge
        if (baseBridge) {
          const { onClose } = baseBridge.getAppData()
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          onClose && onClose({ closeType: 'logout' })
        }
      } else if (response.result === ERROR_PARAMS || response.msg === ERROR_PARAMS) {
        store.dispatch(setGeneralError(ERROR_PARAMS))
      }
      return response
    }
  }

  static resolveError(error) {
    if (error?.response?.status === 401) {
      const baseBridge = window._BaseBridge
      if (baseBridge) {
        const { onClose } = baseBridge.getAppData()
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onClose && onClose({ closeType: 'logout' })
      }
    } else if (error?.response?.status === 500) {
      store.dispatch(setGeneralError('general_api_error'))
      store.dispatch(stopAppLoading())
    }
    throw error
  }

  static postMerchant(endpoint, data = {}, headers = {}) {
    return instance
      .post(getConfig().API_MERCHANT + endpoint, data, {
        headers: Request.header(headers),
      })
      .then(Request.resolveResponse())
      .catch(Request.resolveError)
  }

  static getMerchant(endpoint, params = {}, headers = {}) {
    return instance
      .get(getConfig().API_MERCHANT + endpoint, {
        params,
        headers: Request.header(headers),
      })
      .then(Request.resolveResponse())
      .catch(Request.resolveError)
  }
}

export default Request
