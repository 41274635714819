import { TimeStandard, TimezoneOffset, TimezoneOffsetText } from './constants'
import type { LocaleConfig } from './type'

export enum TimezoneID {
  JAKARTA = 'asia/jakarta',
  MAKASSAR = 'asia/makassar',
  JAYAPURA = 'asia/jayapura',
}

const localeConfig: LocaleConfig = {
  /**
   * timezone
   */
  defaultTimezone: TimezoneID.JAKARTA,
  getTimezone: (timezone?: string) => {
    const lowerTimezone = timezone?.toLocaleLowerCase()
    const config = {
      [TimezoneID.JAKARTA]: {
        timezoneStandardText: TimeStandard.WIB,
        timezoneOffset: TimezoneOffset.UTC7,
        timezoneOffsetText: TimezoneOffsetText[TimezoneOffset.UTC7],
      },
      [TimezoneID.MAKASSAR]: {
        timezoneStandardText: TimeStandard.WITA,
        timezoneOffset: TimezoneOffset.UTC8,
        timezoneOffsetText: TimezoneOffsetText[TimezoneOffset.UTC8],
      },
      [TimezoneID.JAYAPURA]: {
        timezoneStandardText: TimeStandard.WIT,
        timezoneOffset: TimezoneOffset.UTC9,
        timezoneOffsetText: TimezoneOffsetText[TimezoneOffset.UTC9],
      },
    }
    return config[lowerTimezone as TimezoneID]
  },
}

export default localeConfig
