import actions from '../../constants/actionTypes'
import { getDate } from '../../utils/utils'
import { COMMON_CONST } from '../../constants/constants'
import {
  handleChangeFilters,
  handleChangeTableActivePage,
  handleChangeTablePageSize,
  handleCloseDialogGeneric,
  handleOpenDialogGeneric,
} from '../../redux/genericReducerHandler'
import { EnumPage, EnumReportTimeFilterType } from '../../constants/enum'
import { trans } from '../../localization'

const initialState = {
  filters: {
    fromDate: getDate(-6),
    toDate: getDate(),
    restaurant: {
      value: 0,
      label: trans('mod_all_restaurant'),
    },
    timeFilterType: EnumReportTimeFilterType.DELIVERED_TIME,
  },
  table: {
    itemList: [],
    activePage: 1,
    pageSize: COMMON_CONST.PageSize,
  },
}

function exportRestaurantReducer(state = initialState, action) {
  const PAGE = EnumPage.EXPORT_RESTAURANT.id
  const { response, type } = action
  switch (type) {
    case actions.OPEN_DIALOG_GENERIC: {
      return handleOpenDialogGeneric(PAGE, state, action)
    }
    case actions.CLOSE_DIALOG_GENERIC: {
      return handleCloseDialogGeneric(PAGE, state, action)
    }
    case actions.CHANGE_TABLE_PAGE_SIZE: {
      return handleChangeTablePageSize(PAGE, state, action)
    }
    case actions.CHANGE_TABLE_ACTIVE_PAGE: {
      return handleChangeTableActivePage(PAGE, state, action)
    }
    case actions.CHANGE_FILTER_GENERIC: {
      return handleChangeFilters(PAGE, state, action, initialState)
    }

    case actions.RECEIVE_LIST_EXPORT_RESTAURANT: {
      const { itemList } = response
      return {
        ...state,
        table: {
          ...state.table,
          itemList,
          activePage: 1,
        },
      }
    }

    case actions.RECEIVE_ITEM_EXPORT_RESTAURANT: {
      const { exportItem } = response
      const itemList = state.table.itemList.reduce((list, item) => {
        const exportObj = { ...item }
        if (exportItem.reportId === item.reportId) {
          exportObj.status = exportItem.status
          exportObj.reportType = exportItem.reportType
        }
        return [...list, exportObj]
      }, [])
      return {
        ...state,
        table: {
          ...state.table,
          itemList,
        },
      }
    }

    default:
      return state
  }
}

export default exportRestaurantReducer
