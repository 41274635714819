import { Modal, Spin } from 'antd'
import { CSSProperties, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { AdaptiveModalProps, ModalBodySize } from './data'
import { getBodySize } from './utils'
import './index.less'
import { throttle } from 'lodash'

const DESIGN_PADDING = 48

/**
 * 自适应高度弹窗组件
 * 内容过高时内部滚动，内容较小时居中显示
 * @param {AdaptiveModalProps} {
 *   visible,
 *   children,
 *   onClose,
 *   minHeight = '480px',
 *   bodyStyle,
 *   ...rest
 * }
 * @return {*}
 */
const AdaptiveModal = ({
  visible,
  children,
  onClose,
  minHeight = 400,
  padding = DESIGN_PADDING,
  bodyStyle,
  className,
  loading,
  ...rest
}: AdaptiveModalProps) => {
  const [sizes, setSizes] = useState<ModalBodySize>({ auto: false })
  // bodyStyle.height不能由业务定义，只能通过minHeight影响高度的兜底值
  const _bodyStyle = useMemo(
    () => ({
      ...bodyStyle,
      height: sizes.auto && !loading ? 'auto' : sizes.height,
      maxHeight: sizes.height,
      minHeight: bodyStyle?.minHeight ?? minHeight,
      overflow: 'hidden',
    }),
    [bodyStyle, minHeight, loading, sizes],
  )

  const _className = useMemo(() => {
    const cls = ['adaptive-modal']
    if (className) {
      cls.push(className)
    }
    return cls
  }, [className])

  const [shown, setShown] = useState(false)

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose()
    } else {
      setShown(false)
    }
  }, [onClose])

  useEffect(() => {
    setShown(visible ?? false)
  }, [visible])

  useEffect(() => {
    const handleResize = throttle(() => {
      setSizes(getBodySize(_className, padding))
    }, 200)
    // 脱离react event loop
    if (_className.length > 0) {
      setTimeout(() => {
        handleResize()
      }, 0)
    }
    window.addEventListener('resize', handleResize, false)
    return () => {
      window.removeEventListener('resize', handleResize, false)
    }
  }, [_className, loading, padding])

  return (
    <Modal
      {...rest}
      bodyStyle={_bodyStyle as CSSProperties}
      visible={shown}
      centered
      onCancel={handleClose}
      className={_className.join(' ')}
    >
      {loading ? (
        <div className="adaptive-modal-loading">
          <Spin size="large" />
        </div>
      ) : (
        <div className="adaptive-modal-scroller">
          <div className="adaptive-modal-scroller-inner">{children}</div>
        </div>
      )}
    </Modal>
  )
}

export default memo(AdaptiveModal)
